import { useQuery } from '@tanstack/react-query'
import { CURRENT_PROFILE_QUERY_OPTIONS, fetchProfile } from '../Components/User/Profile/util';
import { useLocation } from 'react-router-dom';
import { getUsernameFromLocation } from '../Components/Common/util';

/**
 *
 * @param {string | undefined} username
 * @returns UseQueryResult for given username. If no username is supplied,
 * it will attempt to be derived from the current location.
 */
export function useUserProfile(username = undefined) {
  const locationUsername = getUsernameFromLocation(useLocation());
  let profileUsername = username ? username : locationUsername

  return useQuery({
      queryKey: ['profile', profileUsername],
      queryFn: () => fetchProfile(profileUsername),
      ...CURRENT_PROFILE_QUERY_OPTIONS,
    }
  );
}
