import { Col, Container, Row } from 'react-bootstrap';

const CommunityStandards = () => {
  return (
    <>
      <Container>
        <br />
        <br />
        <Row>
          <Col className="text-center">
            <h1>Community Standards</h1>
          </Col>
        </Row>
        <p>
          Effective 24  January, 2022
        </p>
        <p>
          As part of our ongoing efforts to make Booster a trusted platform for its users, we've developed guidelines
          that go hand-in-hand with our Terms and Conditions. They outline the do’s and don’ts of using Booster so that
          we can best keep you (and your audience) safe. Please take a few minutes to review our Community Standards and
          digest them, since you’re agreeing to abide by them by using your Booster.
        </p>
        <p>
          If you come across content on a Booster that violates the standards below, please report it by contacting us.
        </p>
        <p>
          <b>Adult content</b><br />
          We want to empower creators of all kinds to carve out their own space on the Internet. We also want to make
          sure that visitors, including minors, can make informed decisions before they click. If you’re linking out to
          adult content on your Booster, ensure that:
        </p>
        <p>
          the content you’re linking to is legal in your region,
        </p>
        <p>
          the media on your Booster, such as your avatar, remains suitable for all ages, and
        </p>
        <p>
          you add an age block to the sensitive content, especially if you're embedding content feeds that may contain
          adult material.
        </p>
        <p>
          <b>Hate speech</b><br />
          Booster is a place to share beliefs and ideas as long as it does not cause harm, incite hatred or violence, or
          discriminate against individuals and groups based on race, skin color, national origin, religious beliefs, sex,
          gender, sexual orientation, age, veteran status, disability or medical condition. We’re also not a platform
          that should be used to glorify perpetrators of hatred, violence, or discrimination either.
        </p>
        <p>
          <b>Electoral fraud</b><br />
          Don’t use Booster to impede your local/national electoral process, which includes misleading the public about
          where and how to participate, or about who can participate, and selling or attempting to sell your right to
          vote.
        </p>
        <p>
          <b>Illegal goods and services</b><br />
          Don’t use Booster to advertise or facilitate the sale of regulated or illegal goods or services, including
          (but not limited to):
        </p>
        <ul>
          <li>Alcohol</li>
          <li>Drugs</li>
          <li>Weapons</li>
          <li>Weapon schematics</li>
          <li>Body parts (including body fluids, blood etc.)</li>
          <li>Offers for adoption / surrogacy</li>
          <li>Counterfeits</li>
          <li>Embargoed goods</li>
          <li>Endangered or protected species or parts of</li>
          <li>Fireworks and explosives</li>
          <li>Hazardous / toxic materials</li>
          <li>ID documents</li>
          <li>Personal info or mailing lists</li>
          <li>Regulated plants, insects, animals</li>
          <li>Recalled items</li>
          <li>Stolen items</li>
          <li>Tobacco products, inc. e-cigarettes</li>
          <li>Products offered through multi-level marketing schemes</li>
          <li>Gambling</li>
          <li>Human trafficking or prostitution.</li>
        </ul>
        <p>
          Booster should also not be used to encourage or facilitate any form of illegal activity, including the sale of
          illegal goods or services. Any content that you target at minors must not promote any content (including
          products or services) that is inappropriate, or that exploit or exert undue pressure on the targeted individuals.
        </p>
        <p>
          <b>Spam and fraud</b><br />
          Don’t use Booster to spam or scam visitors, including linking out to external sites which might collect personal
          information under false pretenses.
        </p>
        <p>
          <b>Harassment</b><br />
          Don’t use Booster to target individuals with the intention to intimidate, harass, threaten, or bully.
        </p>
        <p>
          <b>Extremist/terrorist content</b><br />
          Don’t use Booster to advertise, propagandize, praise or collect funds for extremist groups or their activities.
        </p>
        <p>
          <b>Child harm</b><br />
          Booster has a no tolerance policy for child harm. Do not upload or link out to content that harms minors or
          endorses harming minors, including: child sexual abuse material, solicitation of minors, and imagery that
          feature underage individuals within an inappropriate context. We actively report any such cases of child
          endangerment or harm uploaded to or linked out from Booster to relevant authorities, e.g. the National Center
          for Missing and Exploited Children.
        </p>
        <p>
          <b>Self-harm</b><br />
          We want Booster to be a place where users can feel safe as they discuss the journey of recovery in a healthy
          way but we also want users to feel safe when visiting, so we prohibit content that promotes or idealizes
          suicide, self-mutilation, disordered eating, or potentially harmful alternatives to medical treatment.
        </p>
        <p>
          <b>Shocking or violent content</b><br />
          Don’t use Booster to share content intended to shock and disturb visitors such as gore, mutilation, excessive
          violence, or physical abuse.
        </p>
        <p>
          <b>Copyright and trademark</b><br />
          You should not upload content that infringes an individual's intellectual property rights (including copyright,
          trademarks, and patents). If you believe your intellectual property rights are being infringed because of
          content on Booster, please reach out to us.
        </p>
        <p>
          <b>Privacy and impersonation</b><br />
          Maintaining individual privacy is very important on Booster-- it’s why we collect so little information from
          our users. We don’t allow Booster profiles that expose others’ personal identifying information (billing
          details, IDs, etc.), collect identifying information without the appropriate legal basis (including IP
          addresses), or expose private media (image-based sexual abuse, etc.). We also don’t allow Booster profiles
          that impersonate other individuals or organizations.
        </p>
      </Container>
    </>
  );
};

export default CommunityStandards;
