import { hasAccess } from '../User/helper';

export const SURVEY_OPTIONS = {
  not_taken: 'NOT_TAKEN',
  taken: 'TAKEN',
  will_not_take: 'WILL_NOT_TAKE',
}

export const getShouldShow = (user, profile, userProfile, posts, activeMenu) => {
  return hasAccess(user, profile, userProfile, false) &&
    posts.length >= 3 &&
    user.survey_status === 'NOT_TAKEN' &&
    activeMenu === '/links';
}
