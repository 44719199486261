import { Col, Row } from 'react-bootstrap';

const AdvantageItem = ({ imageSrc, title, body, itemCount }) => {
  let imagePosition = '';
  switch(itemCount) {
    case 1:
      imagePosition = 'd-flex justify-content-center';
      break;
    case 2:
      imagePosition = 'd-flex justify-content-end';
      break;
    case 0:
    default:
      imagePosition = 'advantages-image-left';
      break;
  }

  return (
    <Col>
      <div className="advantages-item">
        <Row>
          <Col className={imagePosition}>
            <img src={imageSrc} alt="advantages" className={`advantages-image ${imagePosition}`} />
          </Col>
        </Row>
        <Row className="advantages-body">
          <Col>
            <h3 className="advantages-title">{title}</h3>
            <span className="advantages-subtitle">
              {body}
            </span>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default AdvantageItem;
