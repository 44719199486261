import { Col, Row } from 'react-bootstrap';
import Logo from '../../TopBanner/Logo';
import { linkStyles } from './uril';

const mobileLinkStyle = {
  ...linkStyles,
  fontSize: '14px',
};

const rowStyle = {
  lineHeight: '16px',
};

const headerRowStyle = {
  lineHeight: '34px',
};

const Mobile = () => {
  return (
    <div className="landing-mobile" style={{ padding: '40px'}}>
      <Row>
        <Col>
          <Row style={headerRowStyle}>
            <Col>
                <span style={{ color: '#36198A', fontSize: '16px' }}>
                  Pages
                </span>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <a href="/" style={mobileLinkStyle}>
                Home
              </a>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <a href="/terms_and_conditions" target="terms_and_conditions" style={mobileLinkStyle}>
                Terms and Conditions
              </a>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <a href="/privacy_notice" target="privacy_notice" style={mobileLinkStyle}>
                Privacy Notice
              </a>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <a href="/community_standards" target="community_standards" style={mobileLinkStyle}>
                Community Standards
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Col>
          <Row style={headerRowStyle}>
            <Col>
                <span style={{ color: '#36198A', fontSize: '16px' }}>
                  Contact Us
                </span>
            </Col>
          </Row>
          <Row style={rowStyle}>
            <Col>
              <a href="mailto:polina@boosterstartup.com" style={{ color: '#36198A', textDecoration: 'none', fontSize: '14px' }}>
                polina@boosterstartup.com
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>
        <Col className="text-center">
          <Logo beta={false} />
        </Col>
      </Row>
    </div>
  );
};

export default Mobile;
