import Mobile from './Mobile';
import Desktop from './Desktop';
import Tablet from './Tablet';

const LandingPage = () => {
  return (
    <div>
      <div className="landing-desktop"><Desktop /></div>
      <div className="landing-tablet"><Tablet /></div>
      <div className="landing-mobile"><Mobile /></div>
    </div>
  );
};

export default LandingPage;
