import { Col, Row } from 'react-bootstrap';
import Logo from '../../TopBanner/Logo';
import { linkStyles } from './uril';

const Tablet = () => {
  return (
    <div className="landing-tablet">
      <Row style={{ marginBottom: '20px'}}>
        <Col md={{ offset: 2 }}>
          <Logo beta={false} />
        </Col>
        <Col>
          <Row style={{ lineHeight: '54px' }}>
            <Col>
                <span style={{ color: '#36198A', fontSize: '24px' }}>
                  Pages
                </span>
            </Col>
          </Row>
          <Row style={{ lineHeight: '34px' }}>
            <Col>
                <a href="/" style={linkStyles}>
                  Home
                </a>
            </Col>
          </Row>
          <Row style={{ lineHeight: '34px' }}>
            <Col>
                <a href="/terms_and_conditions" target="terms_and_conditions" style={linkStyles}>
                  Terms and Conditions
                </a>
            </Col>
          </Row>
          <Row style={{ lineHeight: '34px' }}>
            <Col>
                <a href="/privacy_notice" target="privacy_notice" style={linkStyles}>
                  Privacy Notice
                </a>
            </Col>
          </Row>
          <Row style={{ lineHeight: '34px' }}>
            <Col>
                <a href="/community_standards" target="community_standards" style={linkStyles}>
                  Community Standards
                </a>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row style={{ lineHeight: '54px' }}>
            <Col>
                <span style={{ color: '#36198A', fontSize: '24px' }}>
                  Contact Us
                </span>
            </Col>
          </Row>
          <Row style={{ lineHeight: '34px'}}>
            <Col>
              <a href="mailto:polina@boosterstartup.com" style={{ color: '#36198A', textDecoration: 'none', fontSize: '17px' }}>
                polina@boosterstartup.com
              </a>
            </Col>
          </Row>
        </Col>
        <Col md={2}></Col>
      </Row>
    </div>
  );
};

export default Tablet;
