import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {},
  reducers: {
    setUserData: (state, action) => {
      return action.payload;
    },

    updateUserData: (state, action) => {
      state = {
        ...state,
        ...action.payload,
      };

      return state;
    },
  },
});

export const { setUserData, updateUserData} = userSlice.actions;

export default userSlice.reducer;
