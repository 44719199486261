import { Col, Row } from 'react-bootstrap';
import AdvantageItem from './AdvantageItem';

const Advantages = () => {
  const advantages = [{
    imageSrc: 'landing_page/increase_your_reach.png',
    title: 'Increase Your Reach and Influence',
    body: 'Your Booster profile empowers you with a unique link to spread across the web, enhancing visibility, and significantly boosting your global reach.',
  }, {
    imageSrc: 'landing_page/centralize.png',
    title: 'Centralize Your Online Presence',
    body: 'Simplify your social footprint by unifying all your online content, from blogs to social media posts, under one dynamic Booster profile.',
  }, {
    imageSrc: 'landing_page/accessible_and_free.png',
    title: 'Accessible and Free',
    body: 'With Booster\'s intuitive interface, it\'s free and easy to compile your digital identity, no advanced tech skills required. Start in minutes, benefit for a lifetime. ',
  }]

  return (
    <>
      <div className="desktop">
        <Row>
          <Col md={1}></Col>
          {advantages.map((advantage) => {
            return <AdvantageItem {...advantage} key={advantage.imageSrc} />
          })}
          <Col md={1}></Col>
        </Row>
      </div>
      <div className="mobile">
        {advantages.map((advantage, itemCount) => {
          return (
            <Row style={{ marginRight: '0px', marginBlock: '20px' }} key={itemCount}>
              <Col xs={1}></Col>
                <AdvantageItem {...advantage} itemCount={itemCount} />
              <Col xs={1}></Col>
            </Row>
          );
        })}
      </div>
    </>
  );
};

export default Advantages;
