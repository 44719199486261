import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getUserProfile } from '../../User/helper';
import { setProfileData } from '../../../Reducers/profileSlice';

const FetchUserProfile = () => {
  const [checkedProfile, setCheckedProfile] = useState(false);
  const { hasUserData, profile } = useSelector(store => {
    return {
      hasUserData: store.checks.user_data,
      profile: store.profile,
    }
  });
  const dispatch = useDispatch();

  const hasProfile = Object.keys(profile).length !== 0;

  useEffect(() => {
    if (hasUserData && !hasProfile && !checkedProfile) {
      getUserProfile((data) => {
        setCheckedProfile(true);
        dispatch(setProfileData(data))
      }, () => {
        setCheckedProfile(true);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserData])

  return <></>
}

export default FetchUserProfile;
