import axios, { get } from 'axios';
import { LONG_QUERY_OPTIONS, urlFor } from '../Common/util';
import moment from 'moment';

export const A_C_INITIAL_DATA = { data: { available_categories: {} } };

export const A_C_QUERY_OPTIONS = {
  ...LONG_QUERY_OPTIONS,
  initialData: A_C_INITIAL_DATA,
};

export const availableCategoriesOptions = (available_categories) => {
  return Object.entries(available_categories).map(([text, value]) => {
    return { text, value };
  });
};

export const fetchAvailableCategories = () => {
  return get(urlFor('posts/available_categories'));
};

export const fetchPosts = (profileId) => {
  return get(urlFor(`profile/${profileId}/posts`), {
    id: profileId,
  })
}

export const getInitialPostState = (post) => {
  return post || {
    url: '',
    published_at: moment(new Date()).format('YYYY-MM-DD'),
  };
};

export const getAvailableCategories = (res) => {
  return res.data.data.available_categories;
};

export const getPostFormData = (info, profile_id) => {
  const { url, title, published_at, image_url, image, category, schedule, post_restriction } = info;
  const formData = new FormData();
  const data = {
    url,
    title,
    published_at,
    image_url,
    image,
    profile_id,
    category,
    schedule,
    post_restriction,
  };

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'image') {
      if (value) {
        formData.append(key, value, value.name);
      }
    } else if (key === 'post_restriction') {
      if (value) {
        formData.append(key, JSON.stringify(value))
      }
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

export const goToPost = (post) => {
  axios.post(urlFor(`posts/${post.id}/visit`))
  window.open(post.url, `${post.id}_${post.title}`);
};

export const LINK_FORM = 'link_form';
export const SCHEDULE_AND_LOCK = 'schedule_and_lock';
