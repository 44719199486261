import { get, post } from 'axios';
import { urlFor } from '../Common/util';
import { isEmpty } from 'lodash';

export const SUPER_USER = 0;

export const getCurrentUser = (callback, errorHandler) => {
  post('/api/users/sign_in.json').then(({ data }) => {
    callback(data);
  }).catch((res) => {
    if (errorHandler) {
      errorHandler(res);
    } else {
      console.log(res)
    }
  });
};

export const getCurrentUserData = (callback, errorHandler) => {
  get(
    urlFor('user_datum')
  ).then(({ data }) => {
    if (data.user_datum) {
      const {
        user_datum: {
          first_name,
          middle_name,
          last_name,
          user_type,
          survey_status,
        },
      } = data;

      callback({
        first_name,
        last_name,
        middle_name,
        user_type,
        survey_status,
      });
    } else {
      callback({});
    }
  }).catch((res) => {
    errorHandler(res);
  });
};

export const getUserProfile = (callback, errorHandler) => {
  get('/api/v1/user/profile').then(({ data: { profile }}) => {
    callback(profile);
  });
};

export const createProfile = (data, callBack, errorHandler) => {
  const formData = new FormData();

  Object.entries(data.profile).forEach(([key, value]) => {
    if (key === 'logo' && value) {
      formData.append(`profile[${key}]`, value, value.name);
    } else {
      formData.append(`profile[${key}]`, value);
    }
  });

  post(
    '/api/v1/profiles',
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } }
  ).then(({ data: { profile }}) => {
    callBack(profile)
  }).catch((res) => {
    errorHandler(res);
  });
};

export const isSignedIn = (user) => {
  return user && !isEmpty(user);
}

export const hasAccess = (user, profile, userProfile, superUser = true) => {
  return (
    isSignedIn(user) && (
      (profile.id === userProfile.id) ||
      (superUser && user.user_type === SUPER_USER)
    )
  );
};

export const aboutYouHasAllFields = (info) => {
  return info.first_name !== '' &&
    info.last_name !== '' &&
    info.role !== '' &&
    (info.role !== '4' || (info.role === '4' && info.otherRole !== ''));
};

export const alreadyHasData = (user) => {
  if (user) {
    return !!user.first_name;
  }

  return false;
}

export const doneAllUserChecks = (userChecks) => {
  return Object.values(userChecks).every((check) => { return check; });
}

export const getLastUrlPart = (url) => {
  const parts = url.split('/');
  return parts[parts.length - 1];
}
