import { useQuery } from "@tanstack/react-query";
import { fetchPosts } from "../Components/LinkPosts/util";

export function usePosts(profileId) {
  return useQuery({
    queryKey: ['posts', profileId],
    queryFn: () => fetchPosts(profileId),
    enabled: !!profileId,
    placeholderData: { data: { posts: [] } },
  });
}
