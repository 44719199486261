import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import TextField from '../Common/Fields/Generic/Input/TextField';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { aboutYouHasAllFields } from './helper';
import { post } from 'axios';
import { urlFor } from '../Common/util';
import { updateUserData } from '../../Reducers/userSlice';

const EditAboutYouModal = ({ showModal, toggleModal }) => {
  const user = useSelector((store) => { return store.user; });
  const [dirty, setDirty] = useState(false);
  const [info, setInfo] = useState({
    first_name: user.first_name || '',
    middle_name: user.middle_name || '',
    last_name: user.last_name || '',
  });

  const dispatch = useDispatch();

  const handleChange = (field, value) => {
    setInfo({
      ...info,
      [field]: value,
    });
    setDirty(true);
  };

  useEffect(() => {
    if (!info.first_name && user.first_name !== '') {
      setInfo({
        ...info,
        first_name: user.first_name,
        middle_name: user.middle_name,
        last_name: user.last_name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const submitAboutYou = (e) => {
    e.preventDefault();

    if (!dirty) {
      toggleModal();
      return;
    }

    const { first_name, middle_name, last_name } = info;

    const userData = {
      user_datum: {
        first_name,
        middle_name,
        last_name,
      },
    };

    post(
      urlFor('user_datum'),
      userData
    ).then(({ data }) => {
      dispatch(updateUserData(data.user_datum));
    }).catch((res) => {
      console.log(res);
    });
  }

  return (
    <Modal show={showModal} onHide={toggleModal}>
      <Modal.Header closeButton={true}>
        <Modal.Title>About You</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={submitAboutYou}>
          <TextField field="first_name" placeholder="First Name" value={info.first_name} handleChange={handleChange} label="First Name" />
          <TextField field="middle_name" placeholder="Middle Name" value={info.middle_name} handleChange={handleChange} label="Middle Name" />
          <TextField field="last_name" placeholder="Last Name" value={info.last_name} handleChange={handleChange} label="Last Name" />
          <Row>
            <Col className="text-center">
              <Button variant="custom" disabled={!aboutYouHasAllFields(info)} onClick={submitAboutYou}>
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
};

export default EditAboutYouModal;
