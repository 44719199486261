import { AiFillLinkedin, AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai';
import { BsFacebook } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';

export const WEBSITE_URL = 1;
export const FACEBOOK_URL = 2;
export const LINKEDIN_URL = 3;
export const TWITTER_URL = 4;
export const INSTAGRAM_URL = 5;
export const REDDIT_URL = 6;
export const SHARABLE_URL = 7;

export const LINK_TYPE_TO_STR = {
  [FACEBOOK_URL]: 'facebook',
  [LINKEDIN_URL]: 'linkedin',
  [TWITTER_URL]: 'twitter',
  [INSTAGRAM_URL]: 'instagram',
  [REDDIT_URL]: 'reddit',
}

const LINKEDIN_FIELD = {
  field: 'linkedin',
  label: 'LinkedIn URL',
  icon: <AiFillLinkedin />,
};

const INSTAGRAM_FIELD = {
  field: 'instagram',
  label: 'Instagram URL',
  icon: <AiOutlineInstagram />,
};

const FACEBOOK_FIELD = {
  field: 'facebook',
  label: 'Facebook URL',
  icon: <BsFacebook />,
};

const TWITTER_FIELD = {
  field: 'twitter',
  label: 'Twitter URL',
  icon: <AiOutlineTwitter />,
};

const WEBSITE_FIELD = {
  field: 'website',
  label: 'Website URL',
  icon: <CgWebsite />,
};

export const SOCIAL_MEDIA_URL_FIELDS = [
  LINKEDIN_FIELD, INSTAGRAM_FIELD, FACEBOOK_FIELD, TWITTER_FIELD, WEBSITE_FIELD];

export const LINK_TYPE_TO_FIELD = {
  [LINKEDIN_URL]: LINKEDIN_FIELD,
  [INSTAGRAM_URL]: INSTAGRAM_FIELD,
  [FACEBOOK_URL]: FACEBOOK_FIELD,
  [TWITTER_URL]: TWITTER_FIELD,
  [WEBSITE_URL]: WEBSITE_FIELD,
}
