import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasAdminAccess } from '../helper';
import Loading from '../../Common/Loading';
import FetchUser from '../../Common/DataFetchers/FetchUser';
import FetchUserData from '../../Common/DataFetchers/FetchUserData';

const Reports = lazy(() => import('../Reports/index'));
const MostPosts = lazy(() => import('../Reports/MostPosts'));
const ActiveUsers = lazy(() => import('../Reports/ActiveUsers'));
const MostVisitedPosts = lazy(() => import('../Reports/MostVisitedPosts'));
const NewUserReport = lazy(() => import('../Reports/NewUsers'));
const Profiles = lazy( () => import('../Profiles'));
const Status = lazy( () => import('../Profiles/Status'));


const Layout = () => {
  const user = useSelector((store) => store.user);

  if (!hasAdminAccess(user)) {
    return (
      <>
        <FetchUser />
        <FetchUserData />
      </>
    );
  }

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="reports/" exact={true} element={<Reports />} />
          <Route path="reports/*">
            <Route path="new_users" element={<NewUserReport />} />
            <Route path="most_posts" element={<MostPosts />} />
            <Route path="active_users" element={<ActiveUsers />} />
            <Route path="most_visited_posts" element={<MostVisitedPosts />} />
          </Route>
          <Route path="profiles/" exact={true} element={<Profiles />} />
          <Route path="profiles/:id" element={<Status />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default Layout
