import { Col, Container, Row } from 'react-bootstrap';

const TermsAndConditions = () => {
  return (
    <>
      <Container>
        <br />
        <br />
        <Row>
          <Col className="text-center">
            <h1>Terms and Conditions</h1>
          </Col>
        </Row>
        <p>
          Effective 24  January, 2022
        </p>
        <p>
          Welcome to Booster!
        </p>
        <p>
          It's great to have you here. By using Booster, you agree to these Terms & Conditions ("Terms") and the other
          policies that we link to here and on the Platform.
        </p>
        <p>
          These Terms (and the policies we link to) apply to your use of our website (https://market-booster.com), apps
          and any other software or features provided in connection with our services (collectively the "Platform" or
          "Booster"). When we say "we", "our" or "us" in these Terms, we're referring to Booster and our group companies.
        </p>
        <p>
          Please read these Terms carefully and reach out if you have any questions. If you don't agree with them, please don't use Booster.
        </p>
        <p>
          <b>Your account</b><br />
          To create an account and become a Booster user, you must be at least 18. If you're creating an account on
          behalf of someone else, you must have their permission to do so. You're responsible for your account and using
          it in a lawful way.
        </p>
        <p>
          When you create an account, you agree to comply with these Terms and that you're (i) over 18, and (ii) legally
          able to enter these Terms with us. You must provide us with accurate info about yourself and/or the company — if
          anything changes, please let us know so we can update your details.
        </p>
        <p>
          If you're using Booster on behalf of a business or individual, you agree that you're authorized by them to act
          and agree to these Terms on their behalf.
        </p>
        <p>
          You're responsible for anything that happens to your account, so keep your login details and password safe and
          don't share them with anyone. If you think your account has been compromised, please contact us immediately.
        </p>
        <p>
          You must not assign or transfer your account to someone else, or use your account (or allow it to be used by
          anyone) in a way which in our reasonable opinion, causes damage to Booster or our reputation, or infringes
          another's rights or applicable laws and regulations.
        </p>
        <p>
          <b>Your content — what is and isn't allowed</b><br />
          We love the variety of content that our users post on Booster! However, we want to ensure that everyone who
          visits the Platform can do so safely. That's why we have our <a href="/community_standards">Community Standards</a>.
        </p>
        <p>
          Our <a href="/community_standards">Community Standards</a> set out what content is and isn't allowed on Booster,
          so please make sure you follow them, otherwise we may suspend or permanently remove your account.<br />
          When we talk about your "content", we mean the text, graphics, videos, links, and any other materials you add
          to your Booster profile. You're responsible for your content and you warrant that:
          <ul>
            <li>
              it's yours or if you're using third party content, you have all of the rights needed to post it on Booster
              (and to let us use it in accordance with these Terms);
            </li>
            <li>
              none of your content will violate the privacy, publicity, IP or other rights of anyone else;
            </li>
            <li>
              your content will not (i) be misleading or deceptive, intended or designed to misinform, or likely to
              misinform a reasonable person, (ii) cause either of us to break any laws or legal obligations, and (iii)
              bring us into disrepute or damage our reputation;
            </li>
            <li>
              your content doesn't contain any viruses or other harmful code, files, or programs designed to interrupt or damage the functionality of the Platform or any other software, hardware or device;
            </li>
            <li>
              you won't post any unauthorized advertising, solicitations or endorsements on Booster; and
            </li>
            <li>
              all of your content complies with the <a href="/community_standards">Community Standards</a>.
            </li>
          </ul>
        </p>
        <p>
          Since laws and regulations differ from country to country, we may ban content that might be illegal in some
          countries, and to take whatever action we think necessary, including removing content or restricting access
          to it or the Platform.
        </p>
        <p>
          <b>Your content — what we can do with it</b><br />
          We love your content and want to show it off. When you post content on Booster, you give us the right to
          display, use, and share it.
        </p>
        <p>
          When you post content on your profile, you grant us a license to (i) use, publicly display, distribute,
          modify, adapt and create derivative works of such content; and (ii) use your name, image, voice, photograph,
          likeness and any other personal attributes in the content; on the Platform and in our marketing in all media
          (such as our social channels and any other advertising). This license is worldwide, royalty-free and
          perpetual, which means we can use your content anywhere in the world, without paying you fees, for as long as
          we like. You agree that you have all third party rights necessary to post the content on Booster and to grant
          us this license.
        </p>
        <p>
          You’ll retain all of your rights in your content. But keep in mind that your content will be publicly
          accessible, and may be used and re-shared by others on Booster and across the internet.
        </p>
        <p>
          Please don’t share personal info that you don’t want visible to the world, and never post social security
          numbers, passport details or similar info that could cause harm in the wrong hands. You may only post personal
          info relating to others where you have their consent and have kept a record of it.
        </p>
        <p>
          We don’t have to monitor the accuracy, reliability or legality of your content, but we may choose to do so. We
          may modify, remove or restrict access to content at any time in line with these Terms or apply a sensitive
          content warning to content that we deem unsuitable for all audiences.
        </p>
        <p>
          <b>Our Platform</b><br />
          We own the Platform, but give you a limited right to use it for sharing content and viewing and interacting
          with other users’ content. We’re not responsible for any content, products or services made available via
          other users’ profiles.
        </p>
        <p>
          All rights (including IP Rights) in and to the Platform (excluding your content) (the “Booster IP”) are and
          will remain exclusively owned by Booster or our licensors. You won’t acquire any rights in the Booster IP and
          you’re not allowed to use it (including our brand name or logo) for any purpose (such as to imply a
          partnership with, or endorsement from Booster), without our prior written approval.
        </p>
        <p>
          As a user, we grant you a limited, revocable, non-exclusive, non-transferable right to use the Platform to
          create, display, use, play, and upload content in accordance with these Terms. If we provide you with any
          images, icons, themes, fonts, videos, graphics, or other content, you must only use them on your profile and
          comply with any guidelines we make available to you.
        </p>
        <p>
          You must not remove, obscure or alter any proprietary notices or trademarks on the Platform, or make
          unauthorized copies of, reproduce, distribute, license, sell, resell, modify, translate, disassemble,
          decompile, decrypt, reverse engineer, create any derivative works from, or attempt to derive the source code
          of, the Platform or any part of it. If you visit Booster profiles (a “profile visitor”), we grant you a
          limited, non-exclusive, non-transferable right to view and interact with the Platform via user profiles.
        </p>
        <p>
          To the extent allowed by law, we’re not responsible for any opinions, advice, statements, products, services,
          offers or other content posted by other users on their profiles.
        </p>
        <p>
          <b>Privacy & data</b><br />
          We care about the privacy of our users and Profile Visitors.
        </p>
        <p>
          Our <u>Privacy Notice</u> explains how we handle your personal data for our own purposes. Whereas our <u>Data
          Processing Addendum</u> (“DPA”) (which is incorporated into these Terms) sets out your responsibilities (and
          ours) for respecting the privacy rights of visitors to your profile. In creating your account, you agree that
          where the DPA applies to you, you have read, understood and agreed to it. To find out how we use cookies,
          please contact us.
        </p>
        <p>
          All data (and any IP rights in data) that we or the Platform create or generate based on your use (or profile
          visitors’, or other users’ use) of the Platform or content (“Data”) will be owned by us. We may provide Data
          or visualizations of Data to you as part of the service we offer on the Platform (we call this
          “Data Analytics”). We make no representations or warranties as to the accuracy or completeness of the Data
          Analytics, but we’ll try to make it as accurate and complete as we can.
        </p>
        <p>
          <b>Your visitors & customers</b><br />
          You’re responsible for your profile visitors, including customers who purchase goods or services through your
          Booster (collectively “End Users”).
        </p>
        <p>
          You’re solely responsible for the way in which End Users use and interact with your profile and your content;
        </p>
        <p>
          <b>Confidentiality</b><br />
          If we share any confidential information about us or the Platform with you, you must keep it secret and secure.
        </p>
        <p>
          From time to time, we may share info with you that is confidential (e.g. we may reveal new and upcoming
          features to you if you take part in beta testing with us). You must keep this info secret and secure and use
          reasonable measures to prevent others from accessing it.
        </p>
        <p>
          <b>Feedback</b><br />
          We love hearing your ideas on how we can make Booster even better! Sometimes, we may make “beta” functionality
          available to you and seek your feedback.
        </p>
        <p>
          Keep in mind that if you share feedback with us, we’re free to use it however we like, without payment to you
          (or to not use it at all). We may from time to time make certain functionality of the Platform available to
          you in “beta” (or similar). You acknowledge that we are still evaluating and testing such beta functionality
          and it may not be as reliable as other parts of the Platform.
        </p>
        <p>
          <b>Open Source</b><br />
          The app contains open source software.
        </p>
        <p>
          The app contains open source software. Certain open source software components are subject to their own
          applicable license terms, which are available in our Open-Source Content library (accessible under the ‘Legal’
          section in the app). To the extent that any such license terms do not permit the relevant open source component
          to be licensed to you under these Terms, these Terms will not apply to that component, and your rights and
          obligations in respect of that component will be governed by the applicable license terms.
        </p>
        <p>
          <b>Liability</b><br />
          We are not liable for your use of the Platform and you’re responsible for keeping backups of your own content.
        </p>
        <ul>
          <li>
            We are not liable for any damages suffered as a result of downloading, installing, or using the Platform, or
            copying, distributing, or downloading any content from the Platform. You have sole responsibility for the
            adequate protection and backup of your data, content, and devices used in connection with the Platform.
          </li>
          <li>
            You will indemnify us against any loss arising out of a breach by you of these Terms, or a third party claim
            made against us in relation to your content.
          </li>
          <li>
            Neither of us will be liable for any indirect, punitive, special, incidental or consequential damage
            (including loss of business, revenue, profits, use, privacy, data, goodwill or other economic advantage),
            whether for breach of contract, in tort (including negligence) or otherwise, even if we know such damage is
            possible.
          </li>
          <li>
            Our liability to you under or in connection with these Terms or the Platform will not exceed the greater of
            the fees you paid to us at the time the liability arose, or $100.
          </li>
        </ul>
        <p>
          <b>Disclaimer</b><br />
          There’s a few things we disclaim under these Terms.
        </p>
        <p>
          Your use of Booster and any content contained on the Platform is at your sole risk. The Platform is provided
          on an “AS IS” and “AS AVAILABLE” basis, without warranties of any kind, whether express or implied, including
          (but not limited to), up-time or availability, or any implied warranties of merchantability, fitness for a
          particular purpose, non-infringement or course of performance.
        </p>
        <p>
          Booster, its affiliates and its licensors do not make any express or implied warranties or representations,
          including that:
        </p>
        <ul>
          <li>
            the Platform will function uninterrupted, securely or be available at any time or place;
          </li>
          <li>
            any errors or defects will be corrected;
          </li>
          <li>
            the Platform is free of viruses or other harmful components;
          </li>
          <li>
            the Platform is effective or the results of using the Platform will meet your needs; or
          </li>
          <li>
            any content on the Platform (including any user content) is complete, accurate, reliable, suitable or available for any purpose.
          </li>
        </ul>
        <p>
          These Terms apply to the maximum extent permitted by law and nothing in them is intended to exclude, restrict
          or modify legal rights that you may have, which cannot be excluded, restricted or modified by contract. If
          these Terms are governed by the Consumer Protection Law in the United States, our liability to you for not complying with a
          consumer guarantee is limited to re-supply of the services or payment.
        </p>
        <p>
          <b>Third Party Services</b><br />
          We may include in the Platform, access to specific functionality or services which are provided by third
          parties (e.g. a payment portal or online store front).
        </p>
        <p>
          Booster integrates with various third party products and services. Unless we say otherwise, we do not endorse
          (or make any warranties or representations in relation to) any third party product or service and do not offer
          refunds for any amounts you’ve paid to a third party.
        </p>
        <p>
          Your use of any third party product or service may be subject to separate terms and conditions, which you’re
          responsible for reviewing, accepting and complying with. If you don’t accept or comply with those third party
          terms, we may suspend or cancel your account, or limit your access to such services within our Platform.
        </p>
        <p>
          <b>Canceling your account</b><br />
          If you don’t comply with these Terms, we may suspend or cancel your account or limit the functionality of the
          Platform, which you have access to.
        </p>
        <p>
          Depending on what the non-compliance is, we may not use this right to suspend or cancel your account, but if
          there is repeat non-compliance, or a material non-compliance, we are likely to. If we suspend or cancel your
          account, we will usually notify you beforehand (but we don’t have to).
        </p>
        <p>
          If you think your account has been canceled by mistake or you have an issue with these Terms or the Platform,
          please email <a href="mailto:polina@boosterstartup.com">polina@boosterstartup.com</a>. Both us and you will
          use all reasonable efforts to resolve the issue in good faith, and neither of us will bring any legal action
          on the issue until we have spent at least 1 month trying to work it out.
        </p>
        <p>
          <b>Changes</b><br />
          Booster is constantly evolving and improving. From time to time we may make changes to the Platform or these
          Terms. If the change will have a significant negative impact on you, we’ll notify you beforehand.
        </p>
        <p>
          We may need to change these Terms from time-to-time to reflect business updates, changes to the Platform
          (including if we decide to discontinue any functionality, features or part of the Platform), legal or
          commercial reasons, or otherwise to protect our legitimate interests.
        </p>
        <p>
          We can make these changes at any time and it’s your responsibility to check these Terms now and then for any
          changes. However, if a change will have a material negative effect on you, we’ll use our best efforts to
          notify you at least 1 month before the change takes effect (e.g. via a notification on the Platform).
        </p>
        <p>
          Your continued use of the Platform after any changes to the Terms is taken as your acceptance of the revised
          Terms. If you don’t agree with the changes, we ask you to stop using Booster and cancel your account.
        </p>
        <p>
          <b>A few other things</b><br />
          There’s a few more things we need to say before you can use Booster.
        </p>
        <p>
          Laws may apply to you or us as a result of your specific location. If this is the case, and if those laws are
          inconsistent with any part of these Terms, those laws will take precedence to the extent of the inconsistency.
        </p>
        <p>
          These Terms (along with the <u>Privacy Notice</u> and any other policies we link to) make up the entire
          agreement between you and us in connection with the Platform. No other term is to be included, except where
          required by law. All implied terms except those implied by statute which cannot be excluded are expressly
          excluded.
        </p>
        <p>
          If any provision of these Terms is invalid under the law of any country, then it will be severed from these
          Terms in that country and the remainder of these Terms will continue to apply.
        </p>
        <p>
          Our failure to insist upon or enforce any provision of these Terms will not be considered a waiver of any
          provision or right. Neither these Terms, nor the Platform, create a relationship of agent / principal between
          you and us.
        </p>
      </Container>
    </>
  );
};

export default TermsAndConditions;
