import { Link } from 'react-router-dom';

const Logo = ({ mobileStyle, desktopStyle, brandStyle, beta = true }) => {
  const baseBetaStyle = {
    fontFamily: "'Sriracha', cursive",
    fontWeight: 400,
    color: '#EC4E6A',
    position: 'relative',
    width: '20px',
    transform: 'rotate(-17deg)',
    height: '0px',
  };

  const mobileBetaStyle = {
    top: '-32px',
    left: '71px',
    fontSize: '11px',
    ...baseBetaStyle,
    ...mobileStyle,
  };

  const desktopBetaStyle = {
    top: '-25px',
    left: '140px',
    fontSize: '18px',
    ...baseBetaStyle,
    ...desktopStyle,
  }

  const h1Style = brandStyle || {};

  return (
    <Link to="/" style={{ textDecoration: 'none' }}>
      <h1 className="logo" style={h1Style}>
        BOOSTER
      </h1>
      {beta &&
        <>
          <div className="mobile" style={mobileBetaStyle}>
            BETA
          </div>
          <div className="desktop" style={desktopBetaStyle}>
            BETA
          </div>
        </>
      }
    </Link>
  );
};

export default Logo;
