import { Button } from 'react-bootstrap';

const LandingButton = ({ children, style, onClick, className }) => {
  style = {
    fontSize: '30px',
    height: '72px',
    paddingLeft: '32px',
    paddingRight: '32px',
    ...style
  };

  className = className ? className : '';

  return (
    <Button variant="custom" style={style} onClick={onClick} className={className}>
      {children}
    </Button>
  );
};

export default LandingButton;
