import { Col, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import EditAboutYouModal from '../EditAboutYouModal';
import { useSelector } from 'react-redux';
import { alreadyHasData, doneAllUserChecks, hasAccess } from '../helper';

import './banner.css';
import { useUserProfile } from '../../../Hooks/useUserProfile';

const Banner = () => {
  const { data: { data: { profile } } } = useUserProfile();

  const { checks, user, userProfile } = useSelector((store) => {
    return {
      checks: store.checks,
      user: store.user,
      userProfile: store.profile,
    };
  });

  const [shouldShow, setShouldShow] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const userChecks = {
      user_data: checks.user_data,
    };

    setShouldShow(doneAllUserChecks(userChecks) && hasAccess(user, profile, userProfile) && !alreadyHasData(user));
  }, [checks, user, profile, userProfile]);

  if (!shouldShow) {
    return <></>;
  }

  return (
    <>
      <EditAboutYouModal showModal={showModal} toggleModal={toggleModal} />
      <Row className="align-middle more-info-banner" onClick={toggleModal} style={{ width: '100vw' }}>
        {/*<Col md={1} className="d-none d-md-flex"></Col>*/}
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <div className="banner-text">Welcome to BOOSTER! Click here to tell us more about yourself.</div>
        </Col>
        {/*<Col md={1} className="d-none d-md-flex flex-column justify-content-center align-items-center">*/}
        {/*  <Button style={buttonStyle} onClick={}>Start</Button>*/}
        {/*</Col>*/}
      </Row>
    </>
  );
};

export default Banner;
