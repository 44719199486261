import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../../Reducers/userSlice';
import { getCurrentUser } from '../../User/helper';

const FetchUser = () => {
  const user = useSelector(store => store.user);
  const dispatch = useDispatch();

  const isSignedIn = Object.keys(user).length !== 0;

  if (!isSignedIn) {
    getCurrentUser((data) => {
      dispatch(setUserData(data))
     }
    )
  }

  return <></>
}

export default FetchUser;
