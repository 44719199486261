import { createSlice } from '@reduxjs/toolkit';

export const CHECKS_INITIAL_STATE = { user_data: false };

export const checksSlice = createSlice({
  name: 'user_check',
  initialState: CHECKS_INITIAL_STATE,
  reducers: {
    setCheck: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { setCheck } = checksSlice.actions;

export default checksSlice.reducer
