import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { lastUrlPart } from '../../Common/util';
import { useEffect } from 'react';
import { SHARABLE_URL } from '../../RightMenu/constants';
import { getLastUrlPart } from '../helper';

const UserRedirect = () => {
  const { user, profile } = useSelector(store => {
    return {
      user: store.user,
      profile: store.profile,
    }
  });
  const isSignedIn = Object.keys(user).length !== 0;
  const hasProfile = Object.keys(profile).length !== 0;
  const navigate = useNavigate();
  const path = useLocation().pathname;
  const lastPart = lastUrlPart(path);

  useEffect(() => {
    if (!isSignedIn && !['sign_in', 'sign_up'].includes(lastPart)) {
      navigate('/user/sign_in')
    }

    if (isSignedIn && !hasProfile && lastPart !== 'intake') {
      navigate('/user/intake');
    }

    if (isSignedIn && hasProfile) {
      const url = profile.external_links[SHARABLE_URL].link;
      navigate(`/${getLastUrlPart(url)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn, hasProfile])

  return <></>;
}

export default UserRedirect;
