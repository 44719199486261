import { get } from 'axios';
import { SHARABLE_URL } from '../RightMenu/constants';
import { getLastUrlPart, hasAccess } from '../User/helper';
import { isEmpty } from 'lodash';

export const getMs = (mins) => {
  return mins * 60 * 1000;
};

export const LONG_QUERY_OPTIONS = {
  staleTime: getMs(60),
  cacheTime: getMs(60),
  initialDataUpdatedAt: getMs(61),
};

export const lastUrlPart = (pathname) => {
  if (pathname.match(/([^/]+$)/)) {
    return pathname.match(/([^/]+$)/)[0];
  } else {
    return '/';
  }
}

export const urlFor = (action) => {
  return `/api/v1/${action}`;
};

export const getActiveMenu = (username, lastPart) => {
  lastPart = lastPart ? decodeURI(lastPart).toLowerCase() : '/';
  username = username ? username.toLowerCase() : '';

  let activeMenu = '/links';

  if (![username, `/${username}`, `/${username}/`, '/'].includes(lastPart)) {
    activeMenu = `/${lastPart}`;
  }

  return activeMenu;
}

export const getImageFromUrl = (url, successCallback, errorCallback) => {
  get(url, { responseType: 'blob' })
    .then(({ data }) => {
      getImageDataFromFile(data, successCallback);
    }).catch((err) => {
      if (errorCallback) {
        errorCallback(err);
      } else {
        console.log(err);
      }
    });
};

export const getImageDataFromFile = (file, successCallback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    successCallback(reader.result)
  };
};

export const getSharableLink = (profile) => {
  if (profile && profile.external_links && profile.external_links[SHARABLE_URL]) {
    return profile.external_links[SHARABLE_URL].link;
  } else {
    return '';
  }
}

export const getExternalUrl = (user, currentProfile, userProfile) => {
  if (hasAccess(user, currentProfile, userProfile) || isEmpty(userProfile)) {
    return getLastUrlPart(getSharableLink(currentProfile))
  }

  return getLastUrlPart(getSharableLink(Object.values(userProfile)[0]));
};

export const getUsernameFromLocation = (location) =>  {
  return location.pathname.split('/')[1];
};
