import { get } from 'axios';
import { LONG_QUERY_OPTIONS } from '../../Common/util';

const CURRENT_PROFILE_INITIAL_DATA = { data: { profile: {} } };

export const CURRENT_PROFILE_QUERY_OPTIONS = {
  ...LONG_QUERY_OPTIONS,
  initialData: CURRENT_PROFILE_INITIAL_DATA,
  retry: false,
};

export const fetchProfile = (username) => {
  return get(`/api/v1/profiles/${username}`);
};
