import { Button, Form, Modal } from 'react-bootstrap';

import "./survey.css"
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getActiveMenu, getUsernameFromLocation, lastUrlPart, urlFor } from '../Common/util';
import { useLocation } from 'react-router-dom';
import { post } from 'axios';
import { getShouldShow, SURVEY_OPTIONS } from './util';
import { updateUserData } from '../../Reducers/userSlice';
import { useUserProfile } from '../../Hooks/useUserProfile';
import { usePosts } from "../../Hooks/usePosts";

const Survey = () => {
  const username = getUsernameFromLocation(useLocation());
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const [activeMenu, setActiveMenu] = useState('/links')

  const dispatch = useDispatch();

  const { user, userProfile } = useSelector((store) => {
    return {
      user: store.user,
      userProfile: store.profile,
    };
  });
  const { data: { data: { profile } } } = useUserProfile(username);
  const { data: { data: { posts } } } = usePosts(profile.id)

  const lastPart = lastUrlPart(useLocation().pathname);
  useEffect(() => {
    setActiveMenu(getActiveMenu(username, lastPart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username, lastPart]);

  const shouldShow = getShouldShow(user, profile, userProfile, posts, activeMenu);

  const [showSurvey, setShowSurvey] = useState(shouldShow);

  useEffect(() => {
    setShowSurvey(shouldShow);
  }, [shouldShow, setShowSurvey])

  const surveyLink = 'https://docs.google.com/forms/d/e/1FAIpQLSfv7YMGTna5WlYM4fUOjBM-578uNDAcp9lO3tabgF-XRl-7Qw/viewform';

  const takeSurvey = (e) => {
    e.preventDefault();

    post(urlFor('user_datum/take_survey'), {
      user_datum: {
        status: SURVEY_OPTIONS.taken,
      },
    }).then(({ data }) => {
      dispatch(updateUserData(data));
    });

    window.open(surveyLink, '_blank')
    setShowSurvey(false);
  }

  const cancel = (e) => {
    e.preventDefault();

    post(urlFor('user_datum/take_survey'), {
      user_datum: {
        status: dontShowAgain ? SURVEY_OPTIONS.will_not_take : SURVEY_OPTIONS.not_taken,
      },
    }).then(({ data }) => {
      dispatch(updateUserData(data));
    });

    setShowSurvey(false);
  }

  return (
    <Modal show={showSurvey} centered={true} className="survey">
      <Modal.Body className="survey-body">
        <h2>
          Help Us Improve!
        </h2>
        <p>
          Take a quick survey and let us know how we can improve your experience
        </p>

        <Form.Check>
          <Form.Check.Input
            checked={dontShowAgain}
            className="custom"
            onChange={(e) => setDontShowAgain(e.target.checked)} />
          <Form.Check.Label onClick={(e) => setDontShowAgain(!dontShowAgain)}>
            Don't show this again
          </Form.Check.Label>
        </Form.Check>

        <p align="right" style={{ paddingRight: '10px'}}>
          <Button variant="outline-custom" size="sm" onClick={cancel}>
            Cancel
          </Button>
          &nbsp;&nbsp;
          <Button variant="custom" size="sm" onClick={takeSurvey}>
            Take a survey
          </Button>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default Survey;
