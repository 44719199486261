import { Form } from 'react-bootstrap';

import '../../Field.css';
import Message from './Message';

const TextField = ({ label, placeholder, value = '', field, handleChange, message, disabled, type = 'text' }) => {
  const onChange = (e) => {
    if (handleChange) {
      handleChange(field, e.target.value)
    }
  }

  return (
    <>
      <Form.Group>
        {label && <Form.Label className="custom-input-label">{label}</Form.Label>}
        <Form.Control className="custom-input"
                      value={value}
                      onChange={onChange}
                      disabled={disabled}
                      placeholder={placeholder || label || ''}
                      type={type} />
      </Form.Group>
      <Message message={message} />
      <br />
    </>
  );
};

export default TextField;
