import { Route, Routes } from 'react-router-dom';

import Layout from './Components/Common/Layout';
import { default as UserLayout } from './Components/User/Layout';
import { default as AdminLayout } from './Components/Admin/Layout';
import axios from 'axios';
import NotFound from './Components/Common/NotFound';
import LandingPage from './Components/LandingPage';
import PrivacyNotice from './Components/PrivacyNotice';
import TermsAndConditions from './Components/TermsAndConditions';
import CommunityStandards from './Components/CommunityStandards';

axios.defaults.baseURL = process.env.REACT_APP_SERVICE_HOST;

function App() {
  return (
    <Routes>
      <Route path="/404" element={<NotFound />} />
      <Route path="/user/*" element={<UserLayout />} />
      <Route path="/admin/*" element={<AdminLayout />} />
      <Route path="/" exact={true} element={<LandingPage />} />
      <Route path="/privacy_notice" exact={true} element={<PrivacyNotice />} />
      <Route path="/terms_and_conditions" exact={true} element={<TermsAndConditions />} />
      <Route path="/community_standards" exact={true} element={<CommunityStandards />} />
      <Route path="/*" element={<Layout />} />
    </Routes>
  );
}

export default App;
