import { Route, Routes } from 'react-router-dom';

import './layout.css';
import Logo from '../../TopBanner/Logo';

import { Fragment, lazy, Suspense } from 'react'
import { Col, Row } from 'react-bootstrap';
import Loading from '../../Common/Loading';
import FetchUser from '../../Common/DataFetchers/FetchUser';
import FetchUserData from '../../Common/DataFetchers/FetchUserData';
import FetchUserProfile from '../../Common/DataFetchers/FetchUserProfile';
import UserRedirect from './UserRedirect';

const SignIn = lazy(() => import('../SignIn'));
const SignUp = lazy(() => import('../SignUp'));
const Intake = lazy(() => import('../Profile/Intake'));
const SignInFooter = lazy(() => import('../SignIn/SignInFooter'));
const SignUpFooter = lazy(() => import('../SignUp/SignUpFooter'));
const IntakeFooter = lazy(() => import('../Profile/IntakeFooter'));

const Layout = () => {
  return (
    <Fragment>
      <FetchUser />
      <FetchUserData />
      <FetchUserProfile />
      <UserRedirect />
      <Suspense fallback={<Loading />}>
        <div className="user-background"></div>
        <div className="user-content">
          <div className="title">
            <div style={{ display: 'inline-block' }}>
              <Logo mobileStyle={{ left: '145px', top: '-24px' }} desktopStyle={{ left: '137px' }} brandStyle={{ paddingRight: '3px' }} />
            </div>
          </div>
          <div className="page-content">
            <div className="form">
              <Routes>
                <Route path="sign_in" element={<SignIn />} />
                <Route path="sign_up" element={<SignUp />} />
                <Route path="intake" element={<Intake />} />
              </Routes>
            </div>
            <br />
            <Row>
              <Col className="text-center footer">
                <Routes>
                  <Route path="sign_in" element={<SignInFooter />} />
                  <Route path="sign_up" element={<SignUpFooter />} />
                  <Route path="intake" element={<IntakeFooter />} />
                </Routes>
              </Col>
            </Row>
          </div>
        </div>
      </Suspense>
    </Fragment>
  );
};

export default Layout;
