import { SUPER_USER } from '../User/helper';
import { get } from "axios";
import { LONG_QUERY_OPTIONS, urlFor } from "../Common/util";

export const hasAdminAccess = (user) => {
  return user.user_type === SUPER_USER;
};

export const userDataColumnOrder = [
  'id', 'username', 'industry', 'full_name', 'user_type', 'survey_status',
  'number_of_posts', 'sign_in_count', 'last_sign_in', 'last_post_date',
];

export const entryTableHeader = (entry, withCount = true) => {
  let count = '';
  if (withCount) {
    count = `(${Object.keys(entry.counts).length})`;
  }

  return `${entry.start_time} - ${entry.end_time} ${count}`;
};

const P_S_INITIAL_DATA = { data: { profile_statuses: [] } };

export const P_S_QUERY_OPTIONS = {
  ...LONG_QUERY_OPTIONS,
  initialData: P_S_INITIAL_DATA,
};

export const fetchProfileStatus = (id) => {
  return get(urlFor(`admin/profile_statuses/${id}`));
}

const PROFILES_INITIAL_DATA = { data: { profiles: [], pagy: { count: 0, page: 1} } };

export const PROFILES_QUERY_OPTIONS = {
  ...LONG_QUERY_OPTIONS,
  initialData: PROFILES_INITIAL_DATA,
};

export const fetchProfiles = (query, page) => {
  return get(urlFor(`profiles?query=${query}&page=${page}`));
}
