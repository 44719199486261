import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import './NotFound.css';

const NotFound = () => {
  return (
    <>
      <div className="user-background"></div>
      <div className="not-found-container">
        <Container className="text-center">
          <div className="not-found-something-went-wrong">Something Went Wrong</div>
          <div className="not-found-sub-text">This isn’t the page you’re looking for...</div>
          <div className="not-found-404">
            4
            <img alt="Upside Down Face" src="upside-down-face.png" className="not-found-upside-down" />
            4
          </div>
          <div className="not-found-button-container">
            <Link to="/">
              <Button size="lg" variant="outline-custom">Back To Homepage</Button>
            </Link>
          </div>
        </Container>
      </div>
    </>
  );
};

export default NotFound;
