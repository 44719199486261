import { Col, Container, Row } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import { Fragment, lazy, Suspense } from 'react';
import Loading from '../Loading';
import Banner from '../../User/SignUp/Banner';
import FetchUser from '../DataFetchers/FetchUser';
import FetchUserData from '../DataFetchers/FetchUserData';
import FetchUserProfile from '../DataFetchers/FetchUserProfile';
import Survey from '../../Survey';

const LinkPosts = lazy(() => import('../../LinkPosts'));
const TopBanner = lazy(() => import('../../TopBanner'));
const RightMenu = lazy(() => import('../../RightMenu'));
const LeftMenu = lazy(() => import('../../LeftMenu'));
const UserSettings = lazy(() => import('../../User/Settings'));

const Layout = () => {
  return (
    <>
      <FetchUser />
      <FetchUserData />
      <FetchUserProfile />
      <Survey />
      <Container fluid={true}>
        <Banner />
        <Row>
          <Col>
            <Row>
              <Col>
                <Suspense fallback={<Fragment />}>
                  <TopBanner />
                </Suspense>
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="d-sm-block d-md-none">
                <Suspense fallback={<Fragment />}>
                  <RightMenu />
                </Suspense>
              </Col>
              <Col md={2} className="d-none d-md-block">
                <Suspense fallback={<Fragment />}>
                  <LeftMenu />
                </Suspense>
              </Col>
              <Col md={12} lg={6} xl={7} xxl={7}>
                <Suspense fallback={<Loading />}>
                  <Routes>
                    <Route path="/:username">
                      <Route path="links" element={<LinkPosts />} />
                      <Route path="settings" element={<UserSettings />} />
                      <Route path="" element={<LinkPosts />} />
                    </Route>
                  </Routes>
                </Suspense>
              </Col>
              <Col lg={4} xl={3} xxl={3} className="desktop">
                <Suspense fallback={<Fragment />}>
                  <RightMenu />
                </Suspense>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Layout;
