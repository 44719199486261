import { Button, Col, Container, Navbar, Row } from 'react-bootstrap';
import Logo from '../../TopBanner/Logo';
import LandingButton from '../LandingButton';
import { useNavigate } from 'react-router-dom';
import "../index.css";
import Advantages from '../Advantages';
import Footer from '../Footer/Footer';

const Desktop = () => {
  const navigate = useNavigate();

  const goToSignIn = () => {
    navigate('/user/sign_in');
  };

  const goToSignUp = () => {
    navigate('/user/sign_up');
  };

  return (
    <Container fluid={true}>
      <div style={{ position: 'fixed', backgroundColor: 'white', width: '100%', zIndex: 1000 }}>
        <Row style={{ padding: '20px' }}>
          <Navbar>
            <Container fluid={true}>
              <Navbar.Brand>
                <Logo />
              </Navbar.Brand>
              {/*<Nav className="me-auto" style={{ marginLeft: '50px' }}>*/}
              {/*  <Nav.Link href="#home" style={{ color: '#36198A', fontSize: '20' }}>Home</Nav.Link>*/}
              {/*  <Nav.Link href="#pricing" style={{ color: '#36198A', fontSize: '20' }}>Pricing</Nav.Link>*/}
              {/*  <Nav.Link href="#about" style={{ color: '#36198A', fontSize: '20' }}>About Us</Nav.Link>*/}
              {/*</Nav>*/}
              <Col style={{ paddingRight: '50px' }}>
                <Button className="float-end"
                        variant="custom"
                        onClick={goToSignUp}
                        style={{ paddingRight: '20px', paddingLeft: '20px' }}>
                  Sign Up
                </Button>
                <Button className="float-end"
                        variant="outline-custom"
                        onClick={goToSignIn}
                        style={{ border: '0', color: '#282727', marginRight: '10px' }}>
                  Sign In
                </Button>
              </Col>
            </Container>
          </Navbar>
        </Row>
      </div>
      <Row style={{ paddingTop: '140px' }}>
        <Col md={1} />
        <Col md={6}>
          <br />
          <br />
          <h1 style={{ color: '#461AD5', fontWeight: '800', fontSize: '80px', lineHeight: '110px' }}>
            Boost Your Online <br />
            Presence in One <br />
            Click
          </h1>
          <br />
          <br />
          <LandingButton onClick={goToSignUp}>
            Sign Up for Free
          </LandingButton>
        </Col>
        <Col>
          <img src="landing_page/phone_screen_shot.png"
               width={627}
               height={674}
               alt="Phone Screen Shot" />
        </Col>
      </Row>
      <div style={{ background: 'linear-gradient(358.96deg, #F8F7FD 0.86%, rgba(248, 247, 253, 0) 99.07%)', paddingBottom: '30px' }}>
        <Row>
          <Col className="text-center">
            <br />
            <br />
            <br />
            <br />
            <span style={{ color: '#282727', fontSize: '60px' }}>Discover Booster’s Advantages</span>
            <br />
            <br />
            <br />
          </Col>
        </Row>
        <Advantages />
        <br />
        <br />
        <br />
      </div>
      <div style={{ marginTop: '100px' }}>
        <br />
        <br />
        <Row>
          <Col style={{ fontWeight: '700', fontSize: '60px' }} className="text-center">
            <img src="landing_page/try_now.png" style={{ zIndex: '99', width: '950px' }} alt="Try now background" />
            <div style={{ zIndex: '100', marginLeft: '100px', marginTop: '-385px' }}>
              Start <span style={{ color: '#804EEC' }}>Boosting</span> <span>Your Profile Now!</span><br /><br />
              <LandingButton style={{ marginTop: '-100px' }} onClick={goToSignUp}>
                Get Started
              </LandingButton>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
};

export default Desktop;
