import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUserData } from '../../User/helper';
import { isEmpty } from 'lodash';
import { updateUserData } from '../../../Reducers/userSlice';
import { setCheck } from '../../../Reducers/checksSlice';
import { useEffect } from 'react';

const FetchUserData = () => {
  const user = useSelector(store => store.user);
  const dispatch = useDispatch();

  const isSignedIn = !isEmpty(user);

  useEffect(() => {
    if (isSignedIn) {
      getCurrentUserData((data) => {
        if (!isEmpty(data)) {
          dispatch(updateUserData(data))
        }
        dispatch(setCheck({ user_data: true }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSignedIn])

  return <></>;
}

export default FetchUserData;
