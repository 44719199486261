import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../Reducers/userSlice';
import checksReducer from '../Reducers/checksSlice';
import profileSlice from '../Reducers/profileSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    checks: checksReducer,
    profile: profileSlice,
  },
});
