const Message = ({ message, styleOverride }) => {
  if (!message) {
    return <></>
  }

  const style = {
    fontSize: '0.8em',
    color: '#842029',
    marginTop: '5px',
    ...styleOverride,
  };

  return (
    <div style={style}>
      {message}
    </div>
  );
};

export default Message;
