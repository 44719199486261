import Desktop from './Desktop';
import Tablet from './Tablet';
import Mobile from './Mobile';

const Footer = () => {
  return (
    <>
      <Desktop />
      <Tablet />
      <Mobile />
    </>
  );
};

export default Footer;
