import { Col, Container, Row } from 'react-bootstrap';

const PrivacyNotice = () => {
  return (
    <Container>
      <br />
      <br />
      <Row className="text-center">
        <Col>
          <h1>Privacy Notice</h1>
        </Col>
      </Row>
      <p>Effective 24  January, 2022</p>
      <p><b>How does this Privacy Notice apply to me?</b><br />
      <ul>
        <li>This Privacy Notice only applies to personal information we collect as a controller from:</li>
        <li>visitors to our Platform ("<b>Platform Visitors</b>");</li>
        <li>
          individuals, representatives of individuals, or companies that sign up to use our Booster Services
          via free plan ("<b>Free Plan Users</b>"), also known as our "<b>Booster Users</b>";
        </li>
        <li>individuals that sign up to subscribe to and/or follow User Profiles ("<b>Subscribers</b>");</li>
        <li>individuals that visit and interact with User Profiles ("<b>Profile Visitors</b>");</li>
        <li>
          individuals who respond to our surveys, marketing materials or participate in trade promotions or
          competitions that we may run from time to time.
        </li>
      </ul>
      </p>
      <p>
        This Privacy Notice applies to the processing of personal information by Booster as a controller.
        When we talk about Booster acting as a "controller", we mean that Booster determines the purpose and the means
        of the processing (i.e. we make decisions about how we will handle your personal information). Because of the
        nature of our services, we can also act as a "processor" on behalf of Booster Users. This means that, when we
        are instructed by a Booster User, we can facilitate processing of ‘Profile Visitors' and ‘Subscribers' personal
        information on behalf of that Booster User ("<b>Processor Services</b>"). This Privacy Notice does not address
        Processor Services. If you are a Profile Visitor or Subscriber, and want to know how a Booster User handles
        your personal information, please get in touch with the Booster User directly and/or refer to any privacy
        notice on the relevant User Profile.
      </p>
      <p>
        If you provide us with information about another person (if, for example, you are a representative of an
        individual), you must provide them with a copy of this Privacy Notice and let that other person know that
        we use their personal information in the ways set out in this Privacy Notice.
      </p>
      <p>
        <b>What personal information do we collect?</b><br />
        The personal information that we may collect about you broadly falls into the following categories:
      </p>
      <p>
        <i>Information you provide voluntarily</i><br />
        When you sign up to become a Booster User, a Subscriber, use or interact with our Booster Services or staff,
        visit our Platform, visit a User Profile, respond to a survey or participate in a trade promotion we may ask
        you to provide personal information voluntarily. For example, if you are a Free Plan User we will ask you to
        provide your email address, name, hashed password, and industry (industry to which you or your company
        relates). If you are a Subscriber, we will ask you to provide your email address.
      </p>
      <p>
        To opt-out of marketing communications we send you at any time. You can exercise this right by clicking on
        the "unsubscribe" or "opt-out" link in the marketing emails we may send you.
      </p>
      <p>
        You may also provide us with your personal data when you submit queries or make a report to us (such as an
        Intellectual Property Report or Counter Notice). For example, we may ask you to provide your name and email
        address so that we can respond to your queries. If you are making an Intellectual Property Report or Counter
        Notice, we ask you to provide your name, address, email address, phone number and details regarding the
        intellectual property rights concerned.
      </p>
      <p>
        If you are a Profile Visitor, a User might request that you provide your email address, date of birth or age,
        or other personal information in order to access elements of a User Profile (such as locked content). We may
        use the results of such access (i.e. successful or unsuccessful access attempts) to produce aggregated
        statistics for our own internal purposes and to improve the Booster Services.
      </p>
      <p>
        You may also provide personal information voluntarily if you respond to our surveys, marketing materials,
        or through your participation in trade promotions and competitions we may run from time to time.
      </p>
      <p>
        <i>Information that we collect automatically</i><br />
        When you visit our Platform, use our Booster Services, interact with a User Profile, respond to a survey or
        participate in a trade promotion we collect certain information automatically from your device. In some
        countries, including countries in the European Economic Area and UK, this information may be considered
        personal information under applicable data protection laws.
      </p>
      <p>
        Specifically, the information we collect automatically may include information like your IP address, device
        type, unique device identification numbers, browser-type, broad geographic location (e.g. country or
        city-level location), time zone, usage data, diagnostic data and other technical information. We may also
        collect information about how your device has interacted with our Platform, Booster Service or User
        Profiles, including the pages accessed and links clicked.
      </p>
      <p>
        Collecting this information enables us to better understand you, where you come from, and what content is of
        interest to you. We use this information for our internal analytics purposes, to improve the quality and
        relevance of our Platform and Booster Services, to provide hints and tips to our Booster Users and to make
        recommendations of Booster Profiles you might be interested in viewing.
      </p>
      <p>
        Some of this information may be collected using cookies and similar tracking technology, as explained
        further under the heading "<u>Cookie Notice</u>" below.
      </p>
      <p>
        Further, we may undertake automatic scanning of User Profiles and links to determine whether mandatory or
        default sensitive content warnings should be applied and presented to Profile Visitors who wish to access
        the relevant User Profile or linked content, and to determine if any content should be removed or any User
        Profiles should be suspended in line with our Community Standards and/or Terms & Conditions. Where a User
        changes their User Profile, we will also notify relevant Subscribers to that User Profile that updates
        have been made.
      </p>
      <p>
          <i>Information that we obtain from third party sources</i><br />
          From time to time, we may receive personal information about you from third party sources (including from
          service providers that help us run marketing campaigns or competitions and our partners who help us provide
          our Booster Services). In all cases, we will only receive such data where we have checked that these third
          parties either have your consent or are otherwise legally permitted or required to disclose your personal
          information to us.
      </p>
      <p>
          <i>Children's data</i><br />
          Our services are not intended for use by children under the age of 18 (the "Age Limit"). If you are under the
          Age Limit, please do not use the Booster Services and do not provide us with your personal information. If you
          are a parent or guardian and you are aware that an individual (of whom you are a parent or guardian) under the
          Age Limit has provided us with personal information, please contact us. We will, upon notice or discovery,
          take all reasonable efforts to erase or destroy any personal information that may have been collected or
          stored by us about that individual.
      </p>
      <p>
        <b>Why do we collect your personal information?</b><br />
        In general, we will use the information we collect for the purposes described in this Privacy Notice or for
        purposes that we explain to you at the time we collect your personal information. These include:
      </p>
      <ul>
        <li>
          To provide and deliver the Booster Services and to assess, maintain and improve the performance and
          functionality of the Booster Services.
        </li>
        <li>
          To ensure the Booster Services are relevant to you and your device, to notify you about changes to the Booster
          Services, and to deliver targeted and/or localized content based on your user data, location and preferences.
        </li>
        <li>
          For consumer research and to allow you to participate in surveys or interactive features of the Booster
          Services when you choose to do so.
        </li>
        <li>
          To provide customer support and to process and respond to a request.
        </li>
        <li>
          To monitor the usage of the Booster Services and to detect, prevent and address technical issues.
        </li>
        <li>
          To conduct business planning, reporting, and forecasting.
        </li>
        <li>
          To deliver promotional materials, special offers and general information about other goods, services and
          events which we offer that are similar to those that you have already purchased or enquired about unless you
          have opted out of receiving such information.
        </li>
        <li>
          For the administration of our business including for fulfilling and exercising our obligations and rights,
          exercising or defending legal claims, to comply with our legal obligations and law enforcement requests, and
          managing the relationship with you.
        </li>
        <li>
          To verify your identity and to detect fraud and potential fraud, including fraudulent payments and fraudulent
          use of the Booster Services.
        </li>
        <li>
          To include Booster User content as part of our advertising and marketing campaigns to promote Booster.
        </li>
        <li>
          To inform our algorithms so we can deliver the most relevant recommendations to you, including User Profiles
          that you may be interested in.
        </li>
      </ul>
      <p>
        <b>Legal basis for processing personal information</b><br />
        Our legal basis for collecting and using personal information described above will depend on the personal
        information concerned and the specific circumstances in which we collect it.
      </p>
      <p>
        However, we will normally collect personal information from you only where we have your consent, where we need
        the personal information to perform a contract with you, or where the processing is in our legitimate interests
        and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may
        also have a legal obligation to collect personal information from you, or may otherwise need the personal
        information to protect your vital interests or those of another person.
      </p>
      <p>
        If we ask you to provide personal information to comply with a legal requirement we will make this clear at the
        relevant time and advise you whether the provision of your personal information is mandatory or not (as well as
        of the possible consequences if you do not provide your personal information). As indicated above, we require
        certain personal information to enter into a contract with you as a Booster User. Without your personal
        information, we will be unable to provide you with Booster Services available to Booster Users.
      </p>
      <p>
        If we collect and use your personal information in reliance on our legitimate interests (or those of any third
        party), this interest will normally be to develop and improve the Booster Services, to provide additional
        functionality, to ensure appropriate security or to implement sensitive content warnings and content moderation.
        We may have other legitimate interests, and if appropriate, we will make clear to you at the relevant time what
        those legitimate interests are.
      </p>
      <p>
        If you have questions about or need further information concerning the legal basis on which we collect and use
        your personal information, please contact us using the contact details provided under the "<u>Contact Us</u>"
        heading below.
      </p>

      <p>
        <b>Who may we disclose your personal information to?</b><br />
        We may disclose your personal information to the following categories of recipients:
      </p>
      <ul>
        <li>
          to our group companies, third party services providers (for example, to support the delivery of, provide
          functionality on, or help to enhance the security of our Platform or Booster Services), or who otherwise
          process personal information for purposes that are described in this Privacy Notice or notified to you when
          we collect your personal information.
        </li>
        <li>
          to the extent we introduce social media log-ins in the future, we may provide personal data to the relevant
          social media provider to facilitate such log-in.
        </li>
        <li>
          to any competent law enforcement body, regulatory, government agency, court or other third party where we
          believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish
          or defend our legal rights, or (iii) to protect your vital interests or those of any other person;
        </li>
        <li>
          to an actual or potential buyer (and its agents and advisers) in connection with any actual or proposed
          purchase, merger or acquisition of any part of our business, provided that we inform the buyer it must
          use your personal information only for the purposes disclosed in this Privacy Notice; and
        </li>
        <li>
          to any other person with your consent to the disclosure.
        </li>
      </ul>
      <p>
        <b>Disclosure of personal information to other countries</b><br />
        Your personal information may be transferred to, and processed in, countries other than the country in which you
        are a resident. These countries may have data protection laws that are different to the laws of your country
        (and, in some cases, may not be as protective).
      </p>
      <p>
        Specifically, Booster may transfer personal information to other countries in which we do business. Booster may
        also subcontract certain activities and share your personal information with third parties located outside of
        the United States.
      </p>
      <p>
        The countries in which these organizations are located will vary, but, in the course of our business operations,
        we generally disclose personal information to organizations located in the United States. Such transfers are
        made in order to provide the Booster Services and carry out the activities specified in this Privacy Notice.
      </p>
      <p>
        However, we have taken appropriate safeguards to ensure that your personal information will remain protected in
        accordance with this Privacy Notice and applicable data protection laws. These include entering into data
        transfer agreements between our group companies and these can be provided on request. We have implemented
        similar appropriate safeguards with our third party service providers and partners and further details can be
        provided upon request. No transfer of your personal information will take place to an organization or another
        country unless we believe there to be adequate controls in place including the security of your data and other
        personal information. For more details please see the "<u>How do we secure your personal information</u>"
        section below.
      </p>
      <p>
        <b>How do we use cookies and similar tracking technology?</b><br />
        We use cookies and similar tracking technology (collectively, "Cookies") to collect and use personal information
        about you. For further information about the types of Cookies we use, why, and how you can control Cookies,
        please see our Cookie Notice.
      </p>
      <p>
        <b>How long do we retain your personal information?</b><br />
        We will retain your personal information for the period necessary to fulfill the purposes outlined in this
        Privacy Notice and in each case in accordance with applicable legal and regulatory requirements in respect of
        permitted or required retention periods and limitation periods relevant to legal action.
      </p>
      <p>
        <b>How do we secure your personal information?</b><br />
        We have put in place appropriate security measures to prevent your personal information from being accidentally
        lost, used or accessed in an unauthorized way, altered or disclosed.
      </p>
      <p>
        In addition we limit access to your personal information to employees, agents, contractors and other third
        parties who have a business need for access. They will only process your personal information on our
        instructions and they are subject to a duty of confidentiality.
      </p>
      <p>
        While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee
        its absolute security. Therefore, we have put in place procedures to deal with any suspected personal
        information breach and will notify you and any applicable regulator of a breach where we are legally required
        to do so.
      </p>
      <p>
        <b>What are your rights in relation to personal information?</b><br />
        You have the following data protection rights:
        <ul>
          <li>
            If you wish to access, correct or update your personal information, you can do so at any time by using the
            contact details provided under the "Contact Us" heading below.
          </li>
        </ul>
      </p>
      <p>
        We respond to all requests we receive from individuals wishing to exercise their data protection rights in
        accordance with applicable data protection laws.
      </p>
      <p>
        <b>Complaints</b><br />
        We take your privacy concerns seriously. If you have a complaint regarding our handling of your personal
        information or concerning our privacy practices, you may file a complaint with us using the contact details
        provided under the "Contact Us" heading below. We will confirm receipt of your complaint and, if we believe
        necessary, we will open an investigation.
      </p>
      <p>
        We may need to contact you to request further details of your complaint. If an investigation has been opened
        following a complaint made by you, then we will contact you with the result as soon as possible. In the unlikely
        circumstances we are unable to resolve your complaint to your satisfaction, you may contact the local privacy
        and data protection authorities in your jurisdiction.
      </p>
      <p>
        <b>Changes to This Privacy Notice</b><br />
        We may update our Privacy Notice from time to time in response to changing legal, technical or business
        developments. When we update our Privacy notice, we will take appropriate measures to inform you, consistent
        with the significance of the changes we make. We will obtain your consent to any material Privacy Notice changes
        if and where this is required by applicable data protection laws.
      </p>
      <p>
        You can see when this Privacy Notice was last updated by checking the "last updated" date displayed at the top
        of this Privacy Notice.
      </p>

      <p>
        <b>Contact Us</b><br />
        If you have any questions about this Privacy Notice, our privacy practices or if you would like to make a
        request about any personal information we may hold about you, including correction of personal information,
        please contact us:
        <ul>
          <li>
            By email using: <a href="mailto:polina@boosterstartup.com">polina@boosterstartup.com</a>
          </li>
        </ul>
      </p>
      <p>
        <b>Supplemental Information for California Residents</b><br />
        The following section provides supplemental information to California residents, whose personal information we
        have collected. This section applies to the personal information we collected from California residents during
        the 12 months prior to the effective date of this Privacy Notice, depending on interactions with us.
      </p>
      <p>
        1. Collection and Use of Personal Information<br />
        (a) Categories of Personal Information We Have Collected:
      </p>
      <ul>
        <li>
          Identifiers such as name, alias, postal address, online identifier (e.g. cookies) and other similar
          identifiers;
        </li>
        <li>
          Characteristics such as age, gender and other demographic data;
        </li>
        <li>
          Customer Accounts / Commercial information such as the User Profile, records of products or services
          purchased, obtained, or considered;
        </li>
        <li>
          Online usage information such as Internet and other network activity information, such as, but not limited
          to, browsing history, search history and information on interactions with the Booster Services;
        </li>
        <li>
          Geolocation data such as country and zip code;
        </li>
        <li>
          Sensory information such as recordings of customer service calls; and
        </li>
        <li>
          Inferences derived from personal information such as purchase preferences, interests and characteristics.
        </li>
      </ul>
      <p>
        There may be additional information that we collect that meets the definition of personal information under
        California law but is not reflected in a category, in which case we will treat it as personal information as
        required under California law, but will not include it when we are required to describe our practices by
        category of personal information.
      </p>
      <p>
        (b) Categories of Sources of Personal Information that We Collect:
        <ul>
          <li>
            Directly from you. For example, when you create an account, make a purchase, browse our Platform, or use the
            Booster Services.
          </li>
          <li>
            Directly and indirectly from activity on the Platform and/or using the Booster Services.
          </li>
          <li>
            Social networks (to the extent we introduce social media log-ins).
          </li>
          <li>
            Service Providers who provide services on our behalf such as those used to fulfill orders, process your
            payments and requests, verify your information, monitor activity on the Platform and/or using the Booster
            Services, provide analysis and analytics, maintain databases, administer and monitor emails and marketing,
            administer and send mobile messages, serve ads on this and other services, and provide consulting services.
          </li>
        </ul>
      </p>
      <p>
        2. Categories of Personal Information Disclosed
      </p>
      <p>
        We may disclose the following categories of personal information to service providers for a business purpose,
        or, at your direction, with third parties such as sponsors of promotions, sweepstakes, or contests. We have
        disclosed the following:<br />
        <i>Category of Personal Information Disclosed for a Business Purpose</i>
        <ul>
          <li>Identifiers;</li>
          <li>Personal records;</li>
          <li>Characteristics;</li>
          <li>Customer Accounts / Commercial information;</li>
          <li>Online usage information;</li>
          <li>Geolocation data;</li>
          <li>Sensory information; and</li>
          <li>Inferences derived from personal information.</li>
        </ul>
        <i>Categories of Recipients</i>
        <ul>
          <li>Service Providers</li>
          <li>Group companies</li>
          <li>Governmental authorities and agencies</li>
        </ul>
      </p>
      <p>
        We do not sell personal information and we do not believe that our sharing of information would qualify as a
        sale under California law.<br />
        For the avoidance of doubt, we also do not sell personal information of individuals under 16 years of age.
      </p>
      <p>
        3. California Privacy Rights<br />
      </p>
      <p>
        <i>(a) Right to Know</i>
        You have the right to request that we disclose to you the following information about personal information we
        collect from you:
        <ul>
          <li>categories of personal information collected;</li>
          <li>categories of sources of personal information collected;</li>
          <li>the business or commercial purpose for collecting or selling personal information;</li>
          <li>the categories of third parties with whom we share personal information; and</li>
          <li>the specific pieces of personal information we have collected about you over the past 12 months.</li>
        </ul>
      </p>
      <p>
        <i>(b) Right to Request Deletion of Personal Information</i><br />
        You have the right to request the deletion of your personal information collected or maintained by us, subject
        to certain exceptions permitted by law.
      </p>
      <p>
        <i>(c) Right to Opt-Out of Sale of Personal Information</i><br />
        As mentioned above, we do not sell personal information and we do not believe that our sharing of information
        would qualify as a sale under California law. Nonetheless, if you are a California resident who has purchased
        products or services from us, you may submit a request to record your preference to opt out by emailing us at&nbsp;
        <a href="mailto:polina@boosterstartup.com">polina@boosterstartup.com</a>.
      </p>
      <p>
        <i>(d) Right to Non-Discrimination</i><br />
        You have the right to not be treated in a discriminatory manner for exercising your privacy rights. We do not
        use the fact that you have exercised or requested to exercise any privacy rights for any purpose other than
        facilitating a response to your request.
      </p>
      <p>
        4. Exercising Your California Privacy Rights
      </p>
      <p>
        To exercise your rights, you can contact us:
        <ul>
          <li>
            By email at <a href="mailto:polina@boosterstartup.com">polina@boosterstartup.com</a>.
          </li>
        </ul>
        Once we receive your request, we are required to verify that you are the person that is the subject of the
        request. This verification process consists of matching identifying information provided by you with the
        information we have about you in our records. Upon making a request, you will be asked to provide your name,
        email address, and request details. A confirmation email will be sent to the email address you provide to
        begin the process to verify your identity. If we cannot verify your identity, we will not be able to respond
        to your request.
      </p>
      <p>
        You may designate an authorized agent to exercise any of the above privacy rights on your behalf, subject to the
        agent request requirements under California law.
      </p>
      <p>
        Please note, for your safety and security, we will require authorized agents to provide proof of consent and
        designation of the authorized agent for the purpose of making the request, and will require authorized agents
        to provide information necessary to verify the identity of the individual who is the subject of the request.
        We may also require that an individual verify his/her own identity directly with us before we respond to an
        authorized agent's request.
      </p>
      <p>
        We reserve the right to deny requests in certain circumstances, such as where we have a reasonable belief that
        the request is fraudulent.
      </p>
      <p>
        5. Do Not Track
      </p>
      <p>
        California law requires us to let you know how we respond to web browser Do Not Track (DNT) signals. Because
        there currently isn't an industry standard for recognizing or honoring DNT signals, we do not respond to them at
        this time.
      </p>
      <p>
        6. California's Shine the Light law
      </p>
      <p>
        We do not share personal information with third parties for their direct marketing purposes absent your consent
        as defined by California Civil Code Section 1798.83 ("Shine the Light law"). To opt-out of future sharing with
        third parties for their direct marketing purposes, contact us at&nbsp;
        <a href="mailto:polina@boosterstartup.com">polina@boosterstartup.com</a>. Any such request must include
        "California Privacy Rights Request" in the first line of the description and include your name, street address,
        city, state, and ZIP code. Please note that we are only required to respond to one request per customer each
        year.
      </p>
      <p>
        7. Changes
      </p>
      <p>
        We reserve the right to change this Supplemental Information for California Residents from time to time. Changes
        shall become effective on the date they are posted.
      </p>
    </Container>
  );
};

export default PrivacyNotice;
