import { Button, Col, Container, Row } from 'react-bootstrap';
import Logo from '../../TopBanner/Logo';
import LandingButton from '../LandingButton';
import { useNavigate } from 'react-router-dom';
import Advantages from '../Advantages';
import Footer from '../Footer/Footer';

const Mobile = () => {
  const navigate = useNavigate();

  const goToSignIn = () => {
    navigate('/user/sign_in');
  };

  const goToSignUp = () => {
    navigate('/user/sign_up');
  };

  return (
    <Container fluid={true}>
      <div style={{
        position: 'fixed',
        display: 'inline-block',
        width: '100vw',
        paddingTop: '10px',
        paddingLeft: '10px',
        backgroundColor: 'white',
        marginLeft: '-0.75em',
        zIndex: 100,
      }}>
        <Row style={{ padding: '0px 20px 0px 20px', marginRight: '0px' }}>
          <Col xs={6}>
            <Logo />
          </Col>
          <Col xs={6} style={{ paddingTop: '10px'}}>
            <Button className="float-end"
                    variant="custom"
                    onClick={goToSignUp}>
              Sign Up
            </Button>
            <Button className="float-end"
                    variant="outline-custom"
                    onClick={goToSignIn}
                    style={{ border: '0', color: '#282727', marginRight: '10px' }}>
              Sign In
            </Button>
          </Col>
        </Row>
      </div>
      <Row style={{ paddingTop: '100px' }}>
        <Col>
          <h1 style={{ color: '#461AD5', fontWeight: '800', fontSize: '30px', lineHeight: '41px', textAlign: 'center' }}>
            <b>
              Boost Your Online<br />
              Presence in One<br />
              Click
            </b>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: 'center' }}>
          <img src="landing_page/phone_screen_shot.png"
               width={348}
               height={374}
               alt="Phone Screen Shot" />
        </Col>
      </Row>
      <Row style={{ marginTop: '20px'}}>
        <Col style={{ textAlign: 'center' }}>
          <LandingButton style={{ fontSize: '16px', height: '36px' }} onClick={goToSignUp}>
            Sign Up for Free
          </LandingButton>
        </Col>
      </Row>
      <div style={{
        background: 'linear-gradient(180deg, #EFEAFE 47.92%, rgba(239, 234, 254, 0) 100%)',
        paddingBottom: '30px',
        width: '100vw',
        marginLeft: '-0.75rem',
        marginTop: '20px',
      }}>
        <Row style={{ paddingTop: '25px', paddingLeft: '0.75rem', marginRight: '0px' }}>
          <Col className="text-center">
            <span style={{ fontSize: '20px', fontWeight: '700' }}>Discover Booster's Advantages</span>
          </Col>
        </Row>
        <Advantages mobile={true} />
      </div>
      <div style={{
        backgroundColor: 'rgb(239, 234, 254, 0.3)',
        width: '100vw',
        marginLeft: '-0.75rem',
        paddingBottom: '100px',
        paddingTop: '25px',
      }}>
        <Row style={{ marginTop: '100px', marginRight: '0px' }}>
          <Col style={{ fontWeight: '700', fontSize: '25px', position: 'relative' }} className="text-center">
            <img src="landing_page/try_now.png" style={{ zIndex: '99', width: '100%', marginLeft: '-25px' }} alt="Try now background" />
            <div style={{ zIndex: '100', position: 'absolute', top: '30%', width: '100%' }}>
              Start <span style={{ color: '#804EEC' }}>Boosting</span> <span>Your Profile Now!</span><br /><br />
              <LandingButton style={{ marginBottom: '10px', fontSize: '16px', height: '35px' }} onClick={goToSignUp}>
                Get Started
              </LandingButton>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </Container>
  );
};

export default Mobile;
