import { Col, Row, Spinner } from 'react-bootstrap';

const Loading = () => {
  return (
    <Row>
      <Col style={{ height: '100vh' }} className="text-center">
        <Spinner animation="border" style={{ marginTop: '45vh'}} />
      </Col>
    </Row>
  )
};

export default Loading;
